
export const arrOfPlaces = [{ id: "8 Марта п.", text: "8 Марта п.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Абакан", text: "Абакан-(Хакасия Республика)", capital: "Хакасия" },
                            { id: "Азово с.", text: "Азово с.-(Омская Область)", capital: "Омск" },
                            { id: "Академгородок", text: "Академгородок-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Аксу", text: "Аксу-(Павлодарская область)", capital: "Павлодар" },
                            { id: "Актау", text: "Актау-(Мангистауская область)", capital: "Мангыстау" },
                            { id: "Актобе", text: "Актобе-(Актюбинская область)", capital: "Актобе" },
                            { id: "Алапаевск", text: "Алапаевск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Алгабас", text: "Алгабас-(Алматы)", capital: "Алматы" },
                            { id: "Алматы", text: "Алматы-(Алматы)", capital: "Алматы" },
                            { id: "АО Казцинк  Усть-Каменогорск", text: "АО Казцинк  Усть-Каменогорск-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "АО УМЗ Усть-Каменогорск", text: "АО УМЗ Усть-Каменогорск-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Апрелевка", text: "Апрелевка-(Московская Область)", capital: "Москва" },
                            { id: "Арамиль", text: "Арамиль-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Армавир", text: "Армавир-(Краснодарский Край)", capital: "Краснодар" },
                            { id: "Артемовский", text: "Артемовский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Арти", text: "Арти-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Архангельск", text: "Архангельск-(Архангельская Область)", capital: "Архангельск" },
                            { id: "Асбест", text: "Асбест-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Астана", text: "Астана-(Столица Республики Казахстан)", capital: "Астана" },
                            { id: "Астрахань", text: "Астрахань-(Астраханская Область)", capital: "Астрахань" },
                            { id: "Атбасар", text: "Атбасар-(Акмолинская область)", capital: "Кокшетау" },
                            { id: "Атырау", text: "Атырау-(Атырауская область)", capital: "Атырау" },
                            { id: "Ауэзов-Бакырчинский ГОК п.", text: "Ауэзов-Бакырчинский ГОК п.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Ачаир с.", text: "Ачаир с.-(Омская Область)", capital: "Омск" },
                            { id: "Ачит", text: "Ачит-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Аягоз г.", text: "Аягоз г.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Байкалово", text: "Байкалово-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Балашиха", text: "Балашиха-(Московская Область)", capital: "Москва" },
                            { id: "Балхаш", text: "Балхаш-(Карагандинская область)", capital: "Караганда" },
                            { id: "Баранчинский", text: "Баранчинский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Барнаул", text: "Барнаул-(Алтайский Край)", capital: "Барнаул" },
                            { id: "Барышево с.", text: "Барышево с.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Белгород", text: "Белгород-(Белгородская Область)", capital: "Белгород" },
                            { id: "Белоусовка п.", text: "Белоусовка п.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Белоярский", text: "Белоярский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Бердск", text: "Бердск-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Березовский", text: "Березовский-(Кемеровская Область)", capital: "Кемерово" },
                            { id: "Бийск", text: "Бийск-(Алтайский Край)", capital: "Барнаул" },
                            { id: "Бисерть", text: "Бисерть-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Бишкек", text: "Бишкек-(Бишкек)", capital:"Бишкек" },
                            { id: "Благовещенск", text: "Благовещенск-(Амурская Область)", capital: "Благовещенск" },
                            { id: "Богданович", text: "Богданович-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Богословка с.", text: "Богословка с.-(Омская Область)", capital: "Омск" },
                            { id: "Большеречье пгт.", text: "Большеречье пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Большие Уки с.", text: "Большие Уки с.-(Омская Область)", capital: "Омск" },
                            { id: "Большой Исток", text: "Большой Исток-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Боралдай", text: "Боралдай-(Алматы)", capital: "Алматы" },
                            { id: "Бородулиха п.", text: "Бородулиха п.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Бронницы", text: "Бронницы-(Московская Область)", capital: "Москва" },
                            { id: "Брянск", text: "Брянск-(Брянская Область)", capital: "Брянск" },
                            { id: "Буланаш", text: "Буланаш-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Великий Новгород", text: "Великий Новгород-(Новгородская Область)", capital: "Великий Новгород" },
                            { id: "Верея", text: "Верея-(Московская Область)", capital: "Москва" },
                            { id: "Верх-Нейвинский", text: "Верх-Нейвинский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Верх-Тула с.", text: "Верх-Тула с.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Верхние Серги", text: "Верхние Серги-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Верхний Тагил", text: "Верхний Тагил-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Верхняя Пышма", text: "Верхняя Пышма-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Верхняя Салда", text: "Верхняя Салда-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Верхняя Синячиха", text: "Верхняя Синячиха-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Верхняя Сысерть", text: "Верхняя Сысерть-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Верхотурье", text: "Верхотурье-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Видное", text: "Видное-(Московская Область)", capital: "Москва" },
                            { id: "Владивосток", text: "Владивосток-(Приморский Край)", capital: "Владивосток" },
                            { id: "Владимир", text: "Владимир-(Владимирская Область)", capital: "Владимир" },
                            { id: "Волгоград", text: "Волгоград-(Волгоградская Область)", capital: "Волгоград" },
                            { id: "Волжский", text: "Волжский-(Волгоградская Область)", capital: "Волгоград" },
                            { id: "Вологда", text: "Вологда-(Вологодская Область)", capital: "Вологда" },
                            { id: "Володарского", text: "Володарского-(Москва Город)", capital: "Москва" },
                            { id: "Волоколамск", text: "Волоколамск-(Московская Область)", capital: "Москва" },
                            { id: "Волчанск", text: "Волчанск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Воронеж", text: "Воронеж-(Воронежская Область)", capital: "Воронеж" },
                            { id: "Воскресенск", text: "Воскресенск-(Московская Область)", capital: "Москва" },
                            { id: "Высоковск", text: "Высоковск-(Московская Область)", capital: "Москва" },
                            { id: "Гари", text: "Гари-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Глубокое п.", text: "Глубокое п.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Голицыно", text: "Голицыно-(Московская Область)", capital: "Москва" },
                            { id: "Горноуральский", text: "Горноуральский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Горьковское пгт.", text: "Горьковское пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Дегтярск", text: "Дегтярск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Дедовск", text: "Дедовск-(Московская Область)", capital: "Москва" },
                            { id: "Дзержинск", text: "Дзержинск-(Нижегородская Область)", capital: "Нижний Новгород" },
                            { id: "Дзержинский", text: "Дзержинский-(Московская Область)", capital: "Москва" },
                            { id: "Дмитров", text: "Дмитров-(Московская Область)", capital: "Москва" },
                            { id: "Долгопрудный", text: "Долгопрудный-(Московская Область)", capital: "Москва" },
                            { id: "Домодедово", text: "Домодедово-(Московская Область)", capital: "Москва" },
                            { id: "Доскей", text: "Доскей-(Карагандинская область)", capital: "Караганда" },
                            { id: "Дрезна", text: "Дрезна-(Московская Область)", capital: "Москва" },
                            { id: "Дружино с.", text: "Дружино с.-(Омская Область)", capital: "Омск" },
                            { id: "Дружино", text: "Дружино-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Дубна", text: "Дубна-(Московская Область)", capital: "Москва" },
                            { id: "Егорьевск", text: "Егорьевск-(Московская Область)", capital: "Москва" },
                            { id: "Екатеринбург", text: "Екатеринбург-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Ерейментау", text: "Ерейментау-(Акмолинская область)", capital: "Кокшетау" },
                            { id: "Ертарский", text: "Ертарский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Жанаозен", text: "Жанаозен-(Мангистауская область)", capital: "Мангыстау" },
                            { id: "Жанаул п.", text: "Жанаул п.-(Павлодар город)", capital: "Павлодар" },
                            { id: "Жартас, Абайский район", text: "Жартас, Абайский район-(Карагандинская область)", capital: "Караганда" },
                            { id: "Жартас", text: "Жартас-(Карагандинская область)", capital: "Караганда" },
                            { id: "Жезказган", text: "Жезказган-(Улытауская область)", capital: "Жезказган" },
                            { id: "Жезкент п.", text: "Жезкент п.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Железнодорожный", text: "Железнодорожный-(Московская Область)", capital: "Москва" },
                            { id: "Жуковский", text: "Жуковский-(Московская Область)", capital: "Москва" },
                            { id: "Зарайск", text: "Зарайск-(Московская Область)", capital: "Москва" },
                            { id: "Заречный", text: "Заречный-(Пензенская Область)", capital: "Пенза" },
                            { id: "Звенигород", text: "Звенигород-(Московская Область)", capital: "Москва" },
                            { id: "Зеленоград", text: "Зеленоград-(Москва Город)", capital: "Москва" },
                            { id: "Знаменское с.", text: "Знаменское с.-(Омская Область)", capital: "Омск" },
                            { id: "Зыряновск г.", text: "Зыряновск г.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Иваново", text: "Иваново-(Ивановская Область)", capital: "Иваново" },
                            { id: "Ивантеевка", text: "Ивантеевка-(Московская Область)", capital: "Москва" },
                            { id: "Ивдель", text: "Ивдель-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Ижевск", text: "Ижевск-(Удмуртская Республика)", capital: "Ижевск" },
                            { id: "Икша", text: "Икша-(Москва Город)", capital: "Москва" },
                            { id: "Ирбит", text: "Ирбит-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Иргели", text: "Иргели-(Алматы)", capital: "Алматы" },
                            { id: "Иркутск", text: "Иркутск-(Иркутская Область)", capital: "Иркутск" },
                            { id: "Иртышский п.", text: "Иртышский п.-(Омская Область)", capital: "Омск" },
                            { id: "Исилькуль", text: "Исилькуль-(Омская Область)", capital: "Омск" },
                            { id: "Искитим", text: "Искитим-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Истра", text: "Истра-(Московская Область)", capital: "Москва" },
                            { id: "Йошкар-Ола", text: "Йошкар-Ола-(Марий Эл Республика)", capital: "Йошкар-Ола" },
                            { id: "Казань", text: "Казань-(Татарстан Республика)", capita: "Казань" },
                            { id: "Калачинск", text: "Калачинск-(Омская Область)", capital: "Омск" },
                            { id: "Калбатау", text: "Калбатау-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Калининград", text: "Калининград-(Калининградская Область)", capital: "Калининград" },
                            { id: "Калинино с.", text: "Калинино с.-(Омская Область)", capital: "Омск" },
                            { id: "Калуга", text: "Калуга-(Калужская Область)", capital: "Калуга" },
                            { id: "Калья", text: "Калья-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Каменск-Уральский", text: "Каменск-Уральский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Камышлов", text: "Камышлов-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Карабулак", text: "Карабулак-(Южно-Казахстанская область)", capital: "Туркистан" },
                            { id: "Караганды", text: "Караганды-(Карагандинская область)", capital: "Караганда" },
                            { id: "Каркаралинск", text: "Каркаралинск-(Карагандинская область)", capital: "Караганда" },
                            { id: "Карпинск", text: "Карпинск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Карпушиха", text: "Карпушиха-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Каскелен", text: "Каскелен-(Алматинская область)", capital: "Алматы" },
                            { id: "Качканар", text: "Качканар-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Кашира", text: "Кашира-(Московская Область)", capital: "Москва" },
                            { id: "Кедровое", text: "Кедровое-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Кемерово", text: "Кемерово-(Кемеровская Область)", capital: "Кемерово" },
                            { id: "Киров", text: "Киров-(Калужская Область)", capital: "Калуга" },
                            { id: "Киров", text: "Киров-(Кировская Область)", capital: "Киров" },
                            { id: "Кировград", text: "Кировград-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Климовск", text: "Климовск-(Московская Область)", capital: "Москва" },
                            { id: "Клин", text: "Клин-(Московская Область)", capital: "Москва" },
                            { id: "Ключевск", text: "Ключевск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Ключи п.", text: "Ключи п.-(Омская Область)", capital: "Омск" },
                            { id: "Кокшетау", text: "Кокшетау-(Акмолинская область)", capital: "Кокшетау" },
                            { id: "Коломна", text: "Коломна-(Московская Область)", capital: "Москва" },
                            { id: "Колосовка с.", text: "Колосовка с.-(Омская Область)", capital: "Омск" },
                            { id: "Колывань рп.", text: "Колывань рп.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Кольцово пгт.", text: "Кольцово пгт.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Комсомольск-на-Амуре", text: "Комсомольск-на-Амуре-(Хабаровский Край)", capital: "Хабаровск" },
                            { id: "Конаев", text: "Конаев-(Алматинская область)", capital: "Алматы" },
                            { id: "Кормиловка пгт.", text: "Кормиловка пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Королев", text: "Королев-(Московская Область)", capital: "Москва" },
                            { id: "Костанай", text: "Костанай-(Костанайская область)", capital: "Костанай" },
                            { id: "Кострома", text: "Кострома-(Костромская Область)", capital: "Омск" },
                            { id: "Котельники", text: "Котельники-(Московская Область)", capital: "Москва" },
                            { id: "Коуровка", text: "Коуровка-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Коченево пгт.", text: "Коченево пгт.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Красково", text: "Красково-(Москва Город)", capital: "Москва" },
                            { id: "Красная Пахра", text: "Красная Пахра-(Москва Город)", capital: "Москва" },
                            { id: "Красноармейск", text: "Красноармейск-(Московская Область)", capital: "Москва" },
                            { id: "Красногвардейский", text: "Красногвардейский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Красногорск", text: "Красногорск-(Московская Область)", capital: "Москва" },
                            { id: "Краснодар", text: "Краснодар-(Краснодарский Край)", capital: "Краснодар" },
                            { id: "Краснозаводск", text: "Краснозаводск-(Московская Область)", capital: "Москва" },
                            { id: "Краснознаменск", text: "Краснознаменск-(Калининградская Область)", capital: "Калининград" },
                            { id: "Краснообск рп.", text: "Краснообск рп.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Краснотурьинск", text: "Краснотурьинск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Красноуральск", text: "Красноуральск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Красноуфимск", text: "Красноуфимск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Красноярка с.", text: "Красноярка с.-(Омская Область)", capital: "Омск" },
                            { id: "Красноярск", text: "Красноярск-(Красноярский Край)", capital: "Красноярск" },
                            { id: "Крекшино", text: "Крекшино-(Москва Город)", capital: "Москва" },
                            { id: "Криводановка с.", text: "Криводановка с.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Крутинка рп.", text: "Крутинка рп.-(Омская Область)", capital: "Омск" },
                            { id: "КСМ п.", text: "КСМ п.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Кубинка", text: "Кубинка-(Московская Область)", capital: "Москва" },
                            { id: "Кудряшовский п.", text: "Кудряшовский п.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Кузино", text: "Кузино-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Курган", text: "Курган-(Курганская Область)", capital: "Курган" },
                            { id: "Куровское", text: "Куровское-(Московская Область)", capital: "Москва" },
                            { id: "Курск", text: "Курск-(Курская Область)", capital: "Курск" },
                            { id: "Кушва", text: "Кушва-(Свердловская Область)", capital: "Сверловск" },
                            { id: "", text: "Кызылорда-(Кызылординская Область)", capital: "Кызылорда" },
                            { id: "Кытлым", text: "Кытлым-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Левиха", text: "Левиха-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Ленинский п.", text: "Ленинский п.-(Павлодар город)", capital: "Павлодар" },
                            { id: "Лесной", text: "Лесной-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Ликино-Дулево", text: "Ликино-Дулево-(Московская Область)", capital: "Москва" },
                            { id: "Линёво пгт.", text: "Линёво пгт.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Липецк", text: "Липецк-(Липецкая Область)", capital: "Липецк" },
                            { id: "Лобва", text: "Лобва-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Лобня", text: "Лобня-(Московская Область)", capital: "Москва" },
                            { id: "Лосино-Петровский", text: "Лосино-Петровский-(Московская Область)", capital: "Москва" },
                            { id: "Луговской", text: "Луговской-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Лузино с.", text: "Лузино с.-(Омская Область)", capital: "Омск" },
                            { id: "Луховицы", text: "Луховицы-(Московская Область)", capital: "Москва" },
                            { id: "Лыткарино", text: "Лыткарино-(Московская Область)", capital: "Москва" },
                            { id: "Львовский", text: "Львовский-(Москва Город)", capital: "Москва" },
                            { id: "Люберцы", text: "Люберцы-(Московская Область)", capital: "Москва" },
                            { id: "Любинксий пгт.", text: "Любинксий пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Магистральный п.", text: "Магистральный п.-(Омская Область)", capital: "Омск" },
                            { id: "Магнитогорск", text: "Магнитогорск-(Челябинская Область)", capital: "Челябинск" },
                            { id: "Майкудук, Пришахтинск", text: "Майкудук, Пришахтинск-(Карагандинская область)", capital: "Караганда" },
                            { id: "Марьяновка пгт.", text: "Марьяновка пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Матвеевка п.", text: "Матвеевка п.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Миасс", text: "Миасс-(Челябинская Область)", capital: "Челябинск" },
                            { id: "Минск", text: "Минск-(Минская область)", capital: "Минск" },
                            { id: "Михайловск", text: "Михайловск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Михнево", text: "Михнево-(Москва Город)", capital: "Москва" },
                            { id: "Можайск", text: "Можайск-(Московская Область)", capital: "Москва" },
                            { id: "Морозовка с.", text: "Морозовка с.-(Омская Область)", capital: "Омск" },
                            { id: "Москаленки рп.", text: "Москаленки рп.-(Омская Область)", capital: "Омск" },
                            { id: "Москва", text: "Москва-(Москва Город)", capital: "Москва" },
                            { id: "Мочище ст.", text: "Мочище ст.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Мурманск", text: "Мурманск-(Мурманская Область)", capital: "Мурманск" },
                            { id: "Муромцево пгт.", text: "Муромцево пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Мытищи", text: "Мытищи-(Московская Область)", capital: "Москва" },
                            { id: "Набережные Челны", text: "Набережные Челны-(Татарстан Республика)", capita: "Казань" },
                            { id: "Надеждино с.", text: "Надеждино с.-(Омская Область)", capital: "Омск" },
                            { id: "Называевск", text: "Называевск-(Омская Область)", capital: "Омск" },
                            { id: "Наро-Фоминск", text: "Наро-Фоминск-(Московская Область)", capital: "Москва" },
                            { id: "Натальинск", text: "Натальинск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Нахабино", text: "Нахабино-(Москва Город)", capital: "Москва" },
                            { id: "Находка", text: "Находка-(Приморский Край)", capital: "Владивосток" },
                            { id: "Невьянск", text: "Невьянск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Нейво-Рудянка", text: "Нейво-Рудянка-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Нефтекамск", text: "Нефтекамск-(Башкортостан Республика)", capital: "Уфа" },
                            { id: "Нижние Серги", text: "Нижние Серги-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Нижний Новгород", text: "Нижний Новгород-(Нижегородская Область)", capital: "Нижний Новгород" },
                            { id: "Нижний Тагил", text: "Нижний Тагил-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Нижняя Ельцовка п.", text: "Нижняя Ельцовка п.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Нижняя Омка с.", text: "Нижняя Омка с.-(Омская Область)", capital: "Омск" },
                            { id: "Нижняя Салда", text: "Нижняя Салда-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Нижняя Тура", text: "Нижняя Тура-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Никельтау", text: "Никельтау-(Актюбинская область)", capital: "Актобе" },
                            { id: "Нов.Бухтарма п.", text: "Нов.Бухтарма п.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Новая Ляля", text: "Новая Ляля-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Нововоршавка пгт.", text: "Нововоршавка пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Новокузнецк", text: "Новокузнецк-(Кемеровская Область)", capital: "Кемерово" },
                            { id: "Новоомский п.", text: "Новоомский п.-(Омская Область)", capital: "Омск" },
                            { id: "Новороссийск", text: "Новороссийск-(Краснодарский Край)", capital: "Краснодар" },
                            { id: "Новосибирск", text: "Новосибирск-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Новотроицкое с.", text: "Новотроицкое с.-(Омская Область)", capital: "Омск" },
                            { id: "Новоуральск", text: "Новоуральск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Ногинск", text: "Ногинск-(Московская Область)", capital: "Москва" },
                            { id: "Обь", text: "Обь-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "ОбьГэс р-н Варшавская ул.", text: "ОбьГэс р-н Варшавская ул.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "ОбьГэс р-н Софийская ул.", text: "ОбьГэс р-н Софийская ул.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Одесское с.", text: "Одесское с.-(Омская Область)", capital: "Омск" },
                            { id: "Одинцово", text: "Одинцово-(Московская Область)", capital: "Москва" },
                            { id: "Ожерелье", text: "Ожерелье-(Московская Область)", capital: "Москва" },
                            { id: "Озеры", text: "Озеры-(Московская Область)", capital: "Москва" },
                            { id: "Оконешниково рп.", text: "Оконешниково рп.-(Омская Область)", capital: "Омск" },
                            { id: "Октябрьский", text: "Октябрьский-(Московская Область)", capital: "Москва" },
                            { id: "Омск", text: "Омск-(Омская Область)", capital: "Омск" },
                            { id: "Омский п.", text: "Омский п.-(Омская Область)", capital: "Омск" },
                            { id: "Ордынск пгт.", text: "Ордынск пгт.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Орел", text: "Орел-(Орловская Область)", capital: "Орел" },
                            { id: "Оренбург", text: "Оренбург-(Оренбургская Область)", capital: "Оренбург" },
                            { id: "Орехово-Зуево", text: "Орехово-Зуево-(Московская Область)", capital: "Москва" },
                            { id: "Орск", text: "Орск-(Оренбургская Область)", capital: "Оренбург" },
                            { id: "Отеген-Батыр", text: "Отеген-Батыр-(Алматы)", capital: "Алматы" },
                            { id: "Оус", text: "Оус-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Ош", text: "Ош-(Бишкек)", capital:"Бишкек" },
                            { id: "Павловский Посад", text: "Павловский Посад-(Московская Область)", capital: "Москва" },
                            { id: "Павлоградка пгт.", text: "Павлоградка пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Павлодар", text: "Павлодар-(Павлодар город)", capital: "Павлодар" },
                            { id: "Павлодарское с.", text: "Павлодарское с.-(Павлодар город)", capital: "Павлодар" },
                            { id: "Пашино п.", text: "Пашино п.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Пелым", text: "Пелым-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Пенза", text: "Пенза-(Пензенская Область)", capital: "Пенза" },
                            { id: "Первоуральск", text: "Первоуральск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Пермь", text: "Пермь-(Пермский Край)", capital: "Пермь" },
                            { id: "Петровка с.", text: "Петровка с.-(Омская Область)", capital: "Омск" },
                            { id: "Петрозаводск", text: "Петрозаводск-(Карелия Республика)", capital: "Петрозаводск" },
                            { id: "Петропавловск", text: "Петропавловск-(Северо-Казахстанская область)", capital: "Петропавловск" },
                            { id: "Плотниково с.", text: "Плотниково с.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Подольск", text: "Подольск-(Московская Область)", capital: "Москва" },
                            { id: "Покровка с.", text: "Покровка с.-(Омская Область)", capital: "Омск" },
                            { id: "Покровск Уральский", text: "Покровск Уральский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Полевской", text: "Полевской-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Полтавка пгт.", text: "Полтавка пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Полуночное", text: "Полуночное-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Прокопьевск", text: "Прокопьевск-(Кемеровская Область)", capital: "Кемерово" },
                            { id: "Протвино", text: "Протвино-(Московская Область)", capital: "Москва" },
                            { id: "Псков", text: "Псков-(Псковская Область)", capital: "Псков" },
                            { id: "Пушкино с.", text: "Пушкино с.-(Омская Область)", capital: "Омск" },
                            { id: "Пушкино", text: "Пушкино-(Московская Область)", capital: "Москва" },
                            { id: "Пущино", text: "Пущино-(Московская Область)", capital: "Москва" },
                            { id: "Пышма", text: "Пышма-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Пятигорск", text: "Пятигорск-(Ставропольский Край)", capital: "Ставрополь" },
                            { id: "Раменское", text: "Раменское-(Московская Область)", capital: "Москва" },
                            { id: "Ребровка с.", text: "Ребровка с.-(Омская Область)", capital: "Омск" },
                            { id: "Ревда", text: "Ревда-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Реж", text: "Реж-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Реутов", text: "Реутов-(Московская Область)", capital: "Москва" },
                            { id: "Рефтинский", text: "Рефтинский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Риддер г.", text: "Риддер г.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Розовка с.", text: "Розовка с.-(Омская Область)", capital: "Омск" },
                            { id: "Ростов-на-Дону", text: "Ростов-на-Дону-(Ростовская Область)", capital:"Ростов-на-Дону" },
                            { id: "Ростовка п.", text: "Ростовка п.-(Омская Область)", capital: "Омск" },
                            { id: "Рошаль", text: "Рошаль-(Московская Область)", capital: "Москва" },
                            { id: "Рудничный", text: "Рудничный-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Руза", text: "Руза-(Московская Область)", capital: "Москва" },
                            { id: "Русская Поляна пгт.", text: "Русская Поляна пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Рыбинск", text: "Рыбинск-(Ярославская Область)", capital: "Ярославль" },
                            { id: "Рязань", text: "Рязань-(Рязанская Область)", capital: "Рязань" },
                            { id: "Савелово", text: "Савелово-(Москва Город)", capital: "Москва" },
                            { id: "Садовый", text: "Садовый-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Самара", text: "Самара-(Самарская Область)", capital: "Самара" },
                            { id: "Санкт-Петербург", text: "Санкт-Петербург-(Санкт-Петербург Город)", capital: "Санкт-Петербург" },
                            { id: "Саранск", text: "Саранск-(Мордовия Республика)", capital: "Саранск" },
                            { id: "Сарань", text: "Сарань-(Карагандинская область)", capital: "Караганда" },
                            { id: "Саратов", text: "Саратов-(Саратовская Область)", capital: "Саратов" },
                            { id: "Саргатское рп.", text: "Саргатское рп.-(Омская Область)", capital: "Омск" },
                            { id: "Сатпаев", text: "Сатпаев-(Карагандинская область)", capital: "Караганда" },
                            { id: "Севастополь", text: "Севастополь-(Севастополь Город)", capital: "Севастополь" },
                            { id: "Северка", text: "Северка-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Северная промзона", text: "Северная промзона-(Павлодар город)", capital: "Павлодар" },
                            { id: "Северная промышленная зона", text: "Северная промышленная зона-(Карагандинская область)", capital: "Караганда" },
                            { id: "Северный", text: "Северный-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Северодвинск", text: "Северодвинск-(Архангельская Область)", capital: "Архангельск" },
                            { id: "Североуральск", text: "Североуральск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Седельниково с.", text: "Седельниково с.-(Омская Область)", capital: "Омск" },
                            { id: "Селятино", text: "Селятино-(Москва Город)", capital: "Москва" },
                            { id: "Семей", text: "Семей-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Сергиев Посад", text: "Сергиев Посад-(Московская Область)", capital: "Москва" },
                            { id: "Серов", text: "Серов-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Серпухов", text: "Серпухов-(Московская Область)", capital: "Москва" },
                            { id: "Симферополь", text: "Симферополь-(Крым Республика)", capital: "Симферополь" },
                            { id: "Смоленск", text: "Смоленск-(Смоленская Область)", capital: "Смоленск" },
                            { id: "Снегири", text: "Снегири-(Московская Область)", capital: "Москва" },
                            { id: "Солнечногорск", text: "Солнечногорск-(Московская Область)", capital: "Москва" },
                            { id: "Солнцево", text: "Солнцево-(Москва Город)", capital: "Москва" },
                            { id: "Сосенки", text: "Сосенки-(Москва Город)", capital: "Москва" },
                            { id: "Сосьва", text: "Сосьва-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Софрино", text: "Софрино-(Москва Город)", capital: "Москва" },
                            { id: "Сочи", text: "Сочи-(Краснодарский Край)", capital: "Краснодар" },
                            { id: "Среднеуральск", text: "Среднеуральск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Ставрополь", text: "Ставрополь-(Ставропольский Край)", capital: "Ставрополь" },
                            { id: "Старая Купавна", text: "Старая Купавна-(Московская Область)", capital: "Москва" },
                            { id: "Староуткинск", text: "Староуткинск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Степногорск", text: "Степногорск-(Акмолинская область)", capital: "Кокшетау" },
                            { id: "Ступино", text: "Ступино-(Московская Область)", capital: "Москва" },
                            { id: "Сухой Лог", text: "Сухой Лог-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Сысерть", text: "Сысерть-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Таборы", text: "Таборы-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Тавда", text: "Тавда-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Таврическое пгт.", text: "Таврическое пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Таганрог-(Ростовская Область)", text: "Таганрог-(Ростовская Область)", capital:"Ростов-на-Дону" },
                            { id: "Талгар", text: "Талгар-(Алматинская область)", capital: "Алматы" },
                            { id: "Талдом", text: "Талдом-(Московская Область)", capital: "Москва" },
                            { id: "Талдыкорган", text: "Талдыкорган-(Алматинская область)", capital: "Алматы" },
                            { id: "Талица", text: "Талица-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Тамбов", text: "Тамбов-(Тамбовская Область)", capital: "Тамбов" },
                            { id: "Тара", text: "Тара-(Омская Область)", capital: "Омск" },
                            { id: "Тараз", text: "Тараз-(Жамбыльская область)", capital: "Тараз" },
                            { id: "Тарасково", text: "Тарасково-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Таргап", text: "Таргап-(Алматы)", capital: "Алматы" },
                            { id: "Тверь", text: "Тверь-(Тверская Область)", capital: "Тверь" },
                            { id: "Тевриз пгт.", text: "Тевриз пгт.-(Омская Область)", capital: "Омск" },
                            { id: "Темиртау", text: "Темиртау-(Карагандинская область)", capital: "Караганда" },
                            { id: "Токмак", text: "Токмак-(Бишкек)", capital:"Бишкек" },
                            { id: "Толмачево с.", text: "Толмачево с.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Тольятти", text: "Тольятти-(Самарская Область)", capital: "Самара" },
                            { id: "Томилино", text: "Томилино-(Москва Город)", capital: "Москва" },
                            { id: "Томск", text: "Томск-(Томская Область)", capital: "Омск" },
                            { id: "Троицк", text: "Троицк-(Москва Город)", capital: "Москва" },
                            { id: "Троицкий", text: "Троицкий-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Троицкое с.", text: "Троицкое с.-(Омская Область)", capital: "Омск" },
                            { id: "Туапсе", text: "Туапсе-(Краснодарский Край)", capital: "Краснодар" },
                            { id: "Тугулым", text: "Тугулым-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Тула", text: "Тула-(Тульская Область)", capital: "Тула" },
                            { id: "Турген", text: "Турген-(Алматы)", capital: "Алматы" },
                            { id: "Туринск", text: "Туринск-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Туринская Слобода", text: "Туринская Слобода-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Туркестан", text: "Туркестан-(Туркестанская область)", capital: "Туркестан" },
                            { id: "Турне", text: "Турне-(Бельгия)", capital: "Турне" },
                            { id: "Тюкалинск", text: "Тюкалинск-(Омская Область)", capital: "Омск" },
                            { id: "Тюмень", text: "Тюмень-(Тюменская Область)", capital: "Тюмень" },
                            { id: "Узынагаш", text: "Узынагаш-(Алматы)", capital: "Алматы" },
                            { id: "Ульяновск", text: "Ульяновск-(Ульяновская Область)", capital: "Ульяновск" },
                            { id: "Уралец", text: "Уралец-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Уральск", text: "Уральск-(Западно-Казахстанская область)", capital: "Уральск" },
                            { id: "Усть -Заостровка с.", text: "Усть -Заостровка с.-(Омская Область)", capital: "Омск" },
                            { id: "Усть-Ишим с.", text: "Усть-Ишим с.-(Омская Область)", capital: "Омск" },
                            { id: "Усть-Каменогорск", text: "Усть-Каменогорск-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Усть-Таловка п.", text: "Усть-Таловка п.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Уфа", text: "Уфа-(Башкортостан Республика)", capital: "Уфа" },
                            { id: "Уфимский", text: "Уфимский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Уштобе", text: "Уштобе-(Алматинская область)", capital: "Алматы" },
                            { id: "Фрязино", text: "Фрязино-(Московская Область)", capital: "Москва" },
                            { id: "Хабаровск", text: "Хабаровск-(Хабаровский Край)", capital: "Хабаровск" },
                            { id: "Химки", text: "Химки-(Московская Область)", capital: "Москва" },
                            { id: "Хотьково", text: "Хотьково-(Московская Область)", capital: "Москва" },
                            { id: "Хромтау", text: "Хромтау-(Актюбинская область)", capital: "Актобе" },
                            { id: "Центральная промзона", text: "Центральная промзона-(Павлодар город)", capital: "Павлодар" },
                            { id: "Чебоксары", text: "Чебоксары-(Чувашская Республика - Чувашия)", capital: "Чебоксары" },
                            { id: "Челябинск", text: "Челябинск-(Челябинская Область)", capital: "Челябинск" },
                            { id: "Череповец", text: "Череповец-(Вологодская Область)", capital: "Вологда" },
                            { id: "Черлак рп.", text: "Черлак рп.-(Омская Область)", capital: "Омск" },
                            { id: "Черноголовка", text: "Черноголовка-(Московская Область)", capital: "Москва" },
                            { id: "Черноисточник", text: "Черноисточник-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Чехов", text: "Чехов-(Московская Область)", capital: "Москва" },
                            { id: "Шабровский", text: "Шабровский-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Шаля Росс", text: "Шаля Росс-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Шамалган", text: "Шамалган-(Алматы)", capital: "Алматы" },
                            { id: "Шамары", text: "Шамары-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Шатура", text: "Шатура-(Московская Область)", capital: "Москва" },
                            { id: "Шахтинск", text: "Шахтинск-(Карагандинская область)", capital: "Караганда" },
                            { id: "Шахты", text: "Шахты-(Ростовская Область)", capital:"Ростов-на-Дону" },
                            { id: "Шемонаиха п.", text: "Шемонаиха п.-(Восточно-Казахстанская область)", capital: "Усть-Каменогорск" },
                            { id: "Шереметьево", text: "Шереметьево-(Москва Город)", capital: "Москва" },
                            { id: "Шишкин Лес", text: "Шишкин Лес-(Москва Город)", capital: "Москва" },
                            { id: "Шлюз Русская ул.", text: "Шлюз Русская ул.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Шымкент", text: "Шымкент-(Шымкент город)", capital: "Шымкент" },
                            { id: "Щелково", text: "Щелково-(Московская Область)", capital: "Москва" },
                            { id: "Щербакуль рп.", text: "Щербакуль рп.-(Омская Область)", capital: "Омск" },
                            { id: "Щербинка", text: "Щербинка-(Москва Город)", capital: "Москва" },
                            { id: "Щучинск", text: "Щучинск-(Акмолинская область)", capital: "Кокшетау" },
                            { id: "Экибастуз", text: "Экибастуз-(Павлодарская область)", capital: "Павлодар" },
                            { id: "Электрогорск", text: "Электрогорск-(Московская Область)", capital: "Москва" },
                            { id: "Электросталь", text: "Электросталь-(Московская Область)", capital: "Москва" },
                            { id: "Электроугли", text: "Электроугли-(Московская Область)", capital: "Москва" },
                            { id: "Юбилейный", text: "Юбилейный-(Московская Область)", capital: "Москва" },
                            { id: "Юшала", text: "Юшала-(Свердловская Область)", capital: "Сверловск" },
                            { id: "Ярково с.", text: "Ярково с.-(Новосибирская Область)", capital: "Новосибирск" },
                            { id: "Ярославль", text: "Ярославль-(Ярославская Область)", capital: "Ярославль" },
                            { id: "Яхрома", text: "Яхрома-(Московская Область)", capital: "Москва" }
                        ]

export const arrOfTypes = [{ text: "АВТОЗАПЧАСТИ" },
{ text: "АВТОШИНА" },
{ text: "АККУМУЛЯТОРЫ" },
{ text: "АКСЕССУАРЫ" },
{ text: "БЫТОВАЯ ТЕХНИКА" },
{ text: "БЫТОВАЯ ХИМИЯ" },
{ text: "ВЕНТОБОРУДОВАНИЕ" },
{ text: "ГЕОТЕКСТИЛЬ" },
{ text: "ДЕТСКИЕ ТОВАРЫ" },
{ text: "ДОКУМЕНТЫ(более 500 гр.)" },
{ text: "ДОКУМЕНТЫ(менее 500 гр.)" },
{ text: "ДОМОФОНЫ" },
{ text: "ЗАПЧАСТИ" },
{ text: "ЗООТОВАРЫ" },
{ text: "ЗУБНАЯ ПАСТА" },
{ text: "ИГРУШКИ" },
{ text: "ИЗДЕЛИЯ ИЗ ПЛАСТМАСС" },
{ text: "ИЗОЛЯЦИЯ" },
{ text: "ИНСТРУМЕНТЫ" },
{ text: "КАРТИНЫ" },
{ text: "КАТАЛОГИ" },
{ text: "КАНЦТОВАРЫ" },
{ text: "КАФЕЛЬ" },
{ text: "КНИГИ" },
{ text: "КОЛПАЧКИ ПЭТ" },
{ text: "КОМПЛЕКТУЮЩИЕ" },
{ text: "КОНДИТЕРСКИЕ ИЗДЕЛИЯ" },
{ text: "КОСМЕТИКА" },
{ text: "КРЕПЕЖ" },
{ text: "ЛАКОКРАСОЧНЫЕ МАТЕРИАЛЫ" },
{ text: "ЛЕКАРСТВЕННЫЕ ТРАВЫ" },
{ text: "МЕБЕЛЬ" },
{ text: "МЕДТОВАРЫ" },
{ text: "МЕТАЛЛОПРОДУКЦИЯ" },
{ text: "ОБОРУДОВАНИЕ" },
{ text: "ОБУВЬ" },
{ text: "ОГНЕТУШИТЕЛИ" },
{ text: "ОРГТЕХНИКА" },
{ text: "ОДЕЖДА" },
{ text: "ПЕЧАТНАЯ ПРОДУКЦИЯ" },
{ text: "ПИЩЕВЫЕ ДОБАВКИ" },
{ text: "ПОЛИПРОПИЛЕНОВЫЕ МЕШКИ" },
{ text: "ПОСУДА" },
{ text: "ПРОМЫШЛЕННОЕ ОБОРУДОВАНИЕ" },
{ text: "РЕКЛАМНАЯ ПРОДУКЦИЯ" },
{ text: "САНТЕХНИКА" },
{ text: "СПОРТИВНОЕ ПИТАНИЕ" },
{ text: "СПОРТИНВЕНТАРЬ" },
{ text: "СТРОЙМАТЕРИАЛЫ" },
{ text: "ТЕКСТИЛЬ" },
{ text: "ТНП(товары народного потребления)" },
{ text: "УПАКОВКА" },
{ text: "РТИ(резино-технические изделия)" },
{ text: "СВЕТОТЕХНИКА" },
{ text: "ХОЗТОВАРЫ" },
{ text: "УПАКОВОЧНЫЙ МАТЕРИАЛ" },
{ text: "ПРОВОЛОКА" },
{ text: "ДОМ ВЕЩИ" },
{ text: "УТЕПЛИТЕЛЬ" },
{ text: "ШИНЫ" },
{ text: "ТКАНИ" },
{ text: "ЧАЙ В КОРОБКАХ" },
{ text: "ХОЛОДИЛЬНОЕ ОБОРУДОВАНИЕ" },
{ text: "ЭМАЛЬ" },
{ text: "ДРУГОЕ" }
]